import { extendTheme, withDefaultColorScheme } from '@chakra-ui/react';

export default extendTheme(
  withDefaultColorScheme({
    colorScheme: 'primary'
  }),

  
  {
    styles: {
      global: () => ({
        body: {
          color: "var(--standard-black)",
        }
      }),
    },
    fonts: {
      heading: 'var(--standard-header)',
      body: 'var(--standard-font)'
    },

    components: {
      Button: {
        baseStyle: {
          fontweight: 700
        },
        variants: {
          primary: {
            bg: 'primary.500',
            color: 'white',
            _hover: {
              bg: 'primary.600'
            }
          }
        }
      },
    },
    breakpoints: {
      md: '800px'
    },
    colors: {
      primary: {
        50: '#3C7081',
        100: '#2E6A7D',
        200: '#2E6A7D',
        300: '#14617A',
        400: '#14617A',
        500: '#1B5365',
        600: '#1F4754',
        700: '#21363C',
        800: '#202F34'
      },
      secondary: {
        50: '#ffe5e7',
        100: '#fabbbd',
        200: '#f08f93',
        300: '#e86369',
        400: '#e1383e',
        500: '#c71e24',
        600: '#9c161b',
        700: '#700e13',
        800: '#450609',
        900: '#1e0000'
      }
    }
  }
);

import React from 'react';

import { GraphQLProvider } from './src/context/GraphQLProvider';
import { AuthProvider } from './src/context/AuthProvider';
import { WishlistProvider } from './src/context/WishlistProvider';
import { PriceProvider } from './src/context/PriceProvider';
import { CartProvider } from './src/context/CartProvider';
import { SplitProvider } from './src/context/SplitContext';
import { BindProvider } from './src/context/BindProvider';
import { setStorage, getStorage } from './src/helpers/general';
import { ReviewProvider } from './src/context/ReviewProvider';
import { ChakraProvider } from '@chakra-ui/react';
import theme from './src/helpers/chakra/theme';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

require('dotenv').config();

const queryClient = new QueryClient();

export const onInitialClientRender = () => {
  if (typeof window.renderBadge === 'function') {
    try {
      window.renderBadge();
    } catch (err) {
      console.error(err);
    }
  }
};

export const wrapRootElement = ({ element }) => (
  <GraphQLProvider bcPath={process.env.BC_PATH} domain={process.env.SITE_URL}>
    <QueryClientProvider client={queryClient}>
      <AuthProvider bcPath={process.env.BC_PATH} clientId={process.env.BC_CLIENT_ID}>
        <SplitProvider>
          <ChakraProvider theme={theme} resetCSS={false}>
            <WishlistProvider>
              <PriceProvider>
                <ReviewProvider>
                  <CartProvider>
                    <BindProvider>{element}</BindProvider>
                  </CartProvider>
                </ReviewProvider>
              </PriceProvider>
            </WishlistProvider>
          </ChakraProvider>
        </SplitProvider>
      </AuthProvider>
    </QueryClientProvider>
  </GraphQLProvider>
);

export const onRouteUpdate = () => {
  if (!window.navigator.userAgent.includes('Googlebot')) {
    window.analytics && window.analytics.page();
  }
};

export const onClientEntry = () => {
  const maintenanceKey = getStorage('_fmk');
  if (
    process.env.SITE_MAINTENANCE === 'true' &&
    window.location.pathname !== '/maintenance' &&
    window.location.search.indexOf(process.env.GATSBY_MAINTENANCE_KEY) === -1 &&
    maintenanceKey !== process.env.GATSBY_MAINTENANCE_KEY
  ) {
    window.location = '/maintenance';
  } else if (window.location.search.indexOf(process.env.GATSBY_MAINTENANCE_KEY) > -1) {
    setStorage('_fmk', process.env.GATSBY_MAINTENANCE_KEY);
  }
};

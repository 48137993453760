import React, { createContext, useState } from 'react';
import { bcApi } from '../helpers/bigcommerce'

const GraphQLContext = createContext();

export const GraphQLProvider = ({ children, bcPath, domain }) => {
  const [token, setToken] = useState(false);

  const fetchToken = () => {
    return new Promise((res, rej) => {
      if (!token) {
          const date = new Date();
          date.setHours(date.getHours() + 1);
          const expire = Math.floor(date.getTime() / 1000);
          // console.log("Expire", expire)
          // console.log("Domain", domain)

          const body = {
            "channel_id": 1,
            "expires_at": expire,
            "allowed_cors_origins": [domain],
          };
          const bcApiBody = JSON.stringify(body);

          const endpoint = 'storefront/api-token';
          bcApi(endpoint, 'POST', bcApiBody).then(({response}) => {
            // console.log(response);
            if (response && typeof response === 'object' && 'data' in response) {
              setToken({ token: response.data.token });
              res({ token: response.data.token })
            } else {
              rej(response)
            }
          })
          .catch(error => {
              console.error(error);
              rej(error)
          });
      } else {
        // console.log("State set", token);
        res(token)
      }
    });
  };

  const query = (theQuery, theVariables) => {
    return new Promise((res, rej) => {
      fetchToken().then(theToken => {
        // console.log("Token: ", theToken);
        const headers = {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${theToken.token}`
        };

        fetch(`${bcPath}/graphql`, {
          method: 'POST',
          headers,
          body: JSON.stringify({ query: theQuery, variables: theVariables }),
        })
          .then(async result => {
            return ({ response: await result.json(), status: result.status })
          })
          .then(({ response }) => {
            // console.log(response)
            res(response);
          })
          .catch(error => {
            console.log("GQL fail: ", error)
            rej(error)
          });
      }).catch(error => {
        console.error(error);
        rej(error)
      })
    })
  };

  return (
    <GraphQLContext.Provider value={{query}}>{children}</GraphQLContext.Provider>
  );
};

export default GraphQLContext;
import { create } from 'zustand';

export const useGlobalStore = create(set => ({
  frequency: false,
  showSearch: false,
  showHeader: true,
  autoshipProducts: [],
  trackHeaderScroll: false,
  isAutoShip: false,
  toggleAutoship: false,
  frequencySelected: "",
  setFrequencySelected: value => set({ frequencySelected: value }),
  setToggleAutoship: value => set({ toggleAutoship: value }),
  setIsAutoShip: value => set({ isAutoShip: value }),
  setTrackHeaderScroll: value => set({ trackHeaderScroll: value }),
  setFrequency: value => set({ frequency: value }),
  setShowSearch: value => set({ showSearch: value }),
  setShowHeader: value => set({ showHeader: value }),
  addAutoShipProduct: value =>
    set(state => ({ autoshipProducts: [...state.autoshipProducts, value] })),
  removeAutoshipProduct: value =>
    set(state => ({
      autoshipProducts: [...state.autoshipProducts].filter(
        item => item !== value
      )
    }))
}));

import React, { createContext, useState, useEffect, useContext } from 'react';
import { SplitSdk, SplitFactoryProvider } from '@splitsoftware/splitio-react';
import AuthContext from '../context/AuthProvider';

const SplitContext = createContext();

const SplitProvider = ({ children }) => {
  const auth = useContext(AuthContext);
  const [segmentAnonymousId, setSegmentAnonymousId] = useState(null);
  const isLoggedIn = auth && auth.state.isLoggedIn;
  const customerId = auth && auth.state.customerId;

  useEffect(() => {
    const delay = 2000;
    const timeoutId = setTimeout(() => {
      if (typeof window !== 'undefined' && window.analytics) {
        const segmentId = window.analytics.user().anonymousId();
        setSegmentAnonymousId(segmentId);
      }
    }, delay);
    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  const splitKey = isLoggedIn ? customerId : segmentAnonymousId;

  if (typeof window === 'undefined') {
    return null;
  }

  return (
    <SplitFactoryProvider
      factory={SplitSdk({
        core: {
          authorizationKey: process.env.GATSBY_SPLIT_IO_AUTHORIZATION_KEY,
          key: splitKey || process.env.SPLIT_IO_KEY
        }
      })}
    >
      {children}
    </SplitFactoryProvider>
  );
};

export { SplitContext, SplitProvider };

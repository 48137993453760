import { create } from 'zustand';

export const useCartStore = create(set => ({
  cartModal: false,
  upsellAction: false,
  prescription: false,
  service: false,
  updatingCartItem: false,
  setCartModal: value => set({ cartModal: value }),
  setUpsellAction: value => set({ upsellAction: value }),
  setPrescription: value => set({ prescription: value }),
  setService: value => set({ service: value }),
  setUpdatingCartItem: value => set({ updatingCartItem: value }),
}));

/**
 * Auth Helper
 * 
 * Handles the formatting of the URL and actioning the fetch function
 * 
 * @param   {String} action         The Auth API endpoint you need to call.
 * @param   {String|Object} body    (Optional) The body of the call if required. Will access either a stringified object or an object. If an object passed, it will be stringified before entry.
 * 
 * @return  {Object}                {response, status}

    authAction('initLogin', bodyObject).then(({response, status}) => {
        console.log(response, status);
    }).catch(error => console.error(error));
 */
    async function authAction(action, body) {
        const options = {
            method: 'POST',
            credentials: 'same-origin',
            mode: 'same-origin'
        };
        
        if (body) {
            let bodyString = body;
            if (typeof body === 'object') {
            bodyString = JSON.stringify(body);
            }
        
            options.body = bodyString;
        }
        
        const parseJson = async response => {
            const text = await response.text();
            try {
            const json = JSON.parse(text);
            return json;
            } catch (err) {
            return text;
            }
        };
        
        if (!(typeof fetch === 'undefined')) {
            return await fetch(
            `${process.env.LAMBDA_PATH}auth?action=${action}`,
            options
            ).then(async res => ({ response: await parseJson(res), status: res.status }));
        } else {
            return {response: null, status: 404}
        }
    }
    
    async function registerAccount(fields) {
        const response = await authAction('registerAccount', fields);
        // console.log("Auth Helper - Register Account function response:", response);
    
        return response;
    }
    
    // FOR DEVELOPMENT PURPOSES ONLY - SHOULD NOT BE AVAILABLE PUBLICLY
    // async function getToken() {
    //     const response = await authAction('getToken', {data: 'none'});
    //     // console.log("Auth Helper - App Login function response:", response);
    
    //     return response;
    // }
    
    async function initLogin(email, password) {
        const response = await authAction('initLogin', {email, password});
        // console.log("Auth Helper - Init Login function response:", response);
    
        return response;
    }
    
    async function validateLogin(token) {
        const response = await authAction('validateLogin', {token});
        // console.log("Auth Helper - Validate Login function response:", response);
    
        return response;
    }
    
    async function confirmPassword(email, password) {
        const response = await authAction('confirmPassword', {email, password});
        // console.log("Auth Helper - Confirm Password function response:", response);
    
        return response;
    }
    
    export {
        registerAccount,
        // getToken,
        initLogin,
        validateLogin,
        confirmPassword
    }
import React, { createContext, useState, useEffect, useCallback } from 'react';
// import { useStaticQuery, graphql } from 'gatsby';

const PriceContext = createContext();

export const PriceProvider = ({ children }) => {
  const [prices, setPrices] = useState({});

  // const productPrices = useStaticQuery(graphql`
  //   query {
  //     allBigCommerceProductPricing {
  //       edges {
  //         node {
  //           product_id
  //           price {
  //             as_entered
  //             entered_inclusive
  //             tax_exclusive
  //             tax_inclusive
  //           }
  //           retail_price {
  //             as_entered
  //             entered_inclusive
  //             tax_exclusive
  //             tax_inclusive
  //           }
  //           sale_price {
  //             is_on_sale
  //             as_entered
  //             entered_inclusive
  //             tax_exclusive
  //             tax_inclusive
  //           }
  //           calculated_price {
  //             as_entered
  //             entered_inclusive
  //             tax_exclusive
  //             tax_inclusive
  //           }
  //         }
  //       }
  //     }
  //   }
  // `);

  const productPrices = false;

  const fetchPrices = useCallback(() => {
    if (productPrices && productPrices.allBigCommerceProductPricing.edges.length > 0) {
      setPrices(
        productPrices.allBigCommerceProductPricing.edges &&
          productPrices.allBigCommerceProductPricing.edges.reduce((acc, item) => {
            acc[item.node.product_id] = item.node;
            return acc;
          }, {})
      );
    }
  }, [productPrices]);

  useEffect(() => fetchPrices(), [fetchPrices]);

  return (
    <PriceContext.Provider value={prices}>{children}</PriceContext.Provider>
  );
};

export default PriceContext;